import { deleteDataById, getData, getDataBydId, getPaginatedData, insertData, patchData } from './commonService';

export default class ManagerUserService {

    #defaultUrl = "/api/ManagerUser";
    #defaultResourceName = "Yönetici";

    async getAllManagerUsers() {
        return await getPaginatedData(this.#defaultUrl + "/query?FillProjects=true", this.#defaultResourceName);
    }

    async getAllActiveManagerUsers() {
        return await getPaginatedData(this.#defaultUrl + "/query?IsActive=true", this.#defaultResourceName);
    }

    async checkManagerUserExists(gsmNumber, email) {
        let response = await getData(this.#defaultUrl + "/query?Email=" + email, this.#defaultResourceName);
        if(response.isSuccess && response.data.data.length > 0)
        {
            return "Bu Email adresi ile zaten bir kayıt var";
        }

        response = await getData(this.#defaultUrl + "/query?GsmNumber=" + gsmNumber, this.#defaultResourceName);

        if(response.isSuccess && response.data.data.length > 0)
        {
            return "Bu Telefon Numarası ile zaten bir kayıt var";
        }

        return  "Ok";
    }

    async insertManagerUser(dataItem) {
        const createData = {
            "name": dataItem.name,
            "surName": dataItem.surName,
            "email":dataItem.email,
            "password": dataItem.password,
            "userType": dataItem.userType,
            "isActive": dataItem.isActive,
            "gsmNumber": dataItem.gsmNumber,
            "profilePicture": "YoneticiProfil/ProfilDefault.png"
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async getManagerUserById(operatorId) {
        return await getDataBydId(this.#defaultUrl, operatorId, this.#defaultResourceName);
    }

    async deleteById(operatorId) {
        return await deleteDataById(this.#defaultUrl, operatorId, this.#defaultResourceName);
    }

    async updateManagerAuth(managerUserId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "canEditSalary", "value": dataItem.canEditSalary},
        ];
        return await this.patchManager(managerUserId, patchDocument);
    }

    async updateManagerFiles(managerUserId, dataItem)
    {
        const patchDocument = [
            {"op": "replace", "path": "profilePicture", "value": dataItem.profilePicture},
        ];
        return await this.patchManager(managerUserId, patchDocument);
    }

    async updateManagerUserPages(managerUserId, menuState) {
        const patchDocument = [
            {"op": "replace", "path": "menuState", "value":JSON.stringify(menuState)}
    ]
        return await this.patchManager(managerUserId, patchDocument);
    }

    async updateManager(operatorId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "name", "value": dataItem.name},
            {"op": "replace", "path": "surName", "value": dataItem.surName},
            {"op": "replace", "path": "email", "value": dataItem.email},
            {"op": "replace", "path": "gsmNumber", "value": dataItem.gsmNumber},
            {"op": "replace", "path": "userType", "value": dataItem.userType},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
        ];
        if(dataItem.password != '')
        {
            patchDocument.push( {"op": "replace", "path": "password", "value": dataItem.password});
        }

        if(!dataItem.isActive)
        {
            patchDocument.push( {"op": "replace", "path": "passiveDate", "value": dataItem.passiveDate});
            patchDocument.push( {"op": "replace", "path": "passiveReason", "value": dataItem.passiveReason});
        }
        else {
            patchDocument.push( {"op": "replace", "path": "passiveDate", "value": ""});
            patchDocument.push( {"op": "replace", "path": "passiveReason", "value": ""});
        }

        return await this.patchManager(operatorId, patchDocument);
    }
    async patchManager(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }
}
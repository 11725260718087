<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <message-left-panel></message-left-panel>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>



            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12'>

                        <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
                        <div class='align-items-center justify-content-start mb-0 md:mb-0'>
                            <h3 class='mb-0'>Yeni Mesaj</h3>
                        </div>
                    </div>
                </div>

                <span>
					<hr class="mb-4">
				</span>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Oluşturma Tarihi</label>
                            <div class='col-12 md:col-10'>
                                <strong>{{ dataItem.insertDate }}</strong>
                            </div>
                        </div>


                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Kime<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='receiverId' v-model='dataItem.receiverId' :options='receivers'
                                          optionLabel='fullName' option-value='id' placeholder='Seçim Yapın'
                                          @change='receiverSelected'></Dropdown>
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Talep Durumu<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='gender' v-model='dataItem.type' :options='types' optionLabel='name'
                                          option-value='value' placeholder='Seçim Yapın'></Dropdown>
                            </div>
                        </div>
                   
                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesaj Başlığı<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='name' type='text' autocomplete='off' v-model='dataItem.subject' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesaj İçeriği<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <Textarea rows='5' cols='30' id='name' type='text' autocomplete='off'
                                          v-model='dataItem.text' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>SMS Uyarısı</label>
                            <div class='col-12 md:col-10'>
                                <InputSwitch id='sendSmsWarningSwitch' v-model='sendSmsWarning' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Dosya Ekle</label>
                            <div class='col-12 md:col-2'>
                                <FileUpload v-if='upload1Visible && showUploadButton' choose-label='Dosya Ekle 01'
                                            mode='basic'
                                            name='upload[]' :url='uploadUrlForPicture1' :auto='true'
                                            @select='photoUploadStarted("PictureSlot1")'
                                            @upload="photoUploadFinished('PictureSlot1')" />
                                <Button id='deleteFile1' label='Dosya 01 Sil' icon='pi pi-times'
                                        @click='deletePicture("PictureSlot1")'
                                        v-if='!upload1Visible' class='p-button-danger'></Button>
                            </div>
                            <div class='col-12 md:col-2'>
                                <FileUpload v-if='upload2Visible && showUploadButton' choose-label='Dosya Ekle 02'
                                            mode='basic'
                                            name='upload[]' :url='uploadUrlForPicture2' :auto='true'
                                            @before-send='photoUploadStarted("PictureSlot2")'
                                            @upload="photoUploadFinished('PictureSlot2')" />
                                <Button id='deleteFile2' label='Dosya 02 Sil' icon='pi pi-times'
                                        @click='deletePicture("PictureSlot2")'
                                        v-if='!upload2Visible' class='p-button-danger'></Button>
                            </div>
                            <div class='col-12 md:col-2'>
                                <FileUpload v-if='upload3Visible && showUploadButton' choose-label='Dosya Ekle 03'
                                            mode='basic'
                                            name='upload[]' :url='uploadUrlForPicture3' :auto='true'
                                            @before-send='photoUploadStarted("PictureSlot3")'
                                            @upload="photoUploadFinished('PictureSlot3')" />
                                <Button id='deleteFile3' label='Dosya 03 Sil' icon='pi pi-times' @click='deletePicture("PictureSlot3")' v-if='!upload3Visible' class='p-button-danger'></Button>
                            </div>
                        </div>

                        <div class='field grid'>
                            <label class='col-12 mb-2 md:col-2 md:mb-4'></label>
                            <div class='col-12 md:col-10'>
                                <Button id='save' label='Gönder' icon='pi pi-send' @click='save' v-bind:disabled='isProcessing'></Button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>

import { showErrorMessage, showSuccessMessage, showValidationMessage } from '@/components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import { formatDate, getUserId } from '../common/commonConstantFunctions';
import ManagerUserService from '@/services/managerUserService';
import MessageService from '@/services/messageService';
import moment from 'moment';
import MessageLeftPanel from '@/pages/messages/messageLeftPanel.vue';
import SmsService from '@/services/smsService';

export default {
    _messageService: null,
    _managerUserService: null,
    _smsService:null,
    components: { MessageLeftPanel, AdminToast },

    created() {
        this._smsService = new SmsService();
        this._managerUserService = new ManagerUserService();
        this._messageService = new MessageService();
    },
    async mounted() {
        this.dataItem.senderId = getUserId();
        this.dataItem.insertDate = this.formatDate(moment().toDate());
        let managerUserResponse = await this._managerUserService.getAllActiveManagerUsers();
        if (managerUserResponse.isSuccess) {
            this.receivers = managerUserResponse.data;
        }
    },
    computed: {
      showUploadButton() {
          return this.dataItem.id > 0;
      }
    },
    data() {
        return {
            sendSmsWarning:true,
            uploadUrlForPicture1: '',
            uploadUrlForPicture2: '',
            uploadUrlForPicture3: '',
            isProcessing: false,
            display: false,
            visibleLeft: false,
            upload1Visible: true,
            upload2Visible: true,
            upload3Visible: true,
            dataItem: {
                id: 0,
                receiverId: 0,
                senderId: 0,
                parentMessageId: 0,
                text: '',
                type: '',
                status: 'New',
                subject: '',
                insertDate: '',
                closeDate: '',
            },
            receivers: [],
            types: [
                {
                    name: 'Normal',
                    value: 'Normal',
                },
                {
                    name: 'Acil',
                    value: 'Acil',
                },
            ],
        };
    },

    methods: {
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {

                this.dataItem.status = 'Açık';
                this.dataItem.isActive = true;
                let insertResponse = await this._messageService.updateMessage(this.dataItem.id, this.dataItem);
                if (insertResponse.isSuccess) {
                    let managerUserResponse = await this._managerUserService.getManagerUserById(this.dataItem.receiverId);

                    if(managerUserResponse.isSuccess && this.sendSmsWarning == true)
                    {
                        let message = "Mesaj kutunuza "+ formatDate(moment().toDate()) +" tarihinde gelen bir talep var. Başlığı " + this.dataItem.subject
                        await this._smsService.sendSingleSms({
                            phoneNumber: managerUserResponse.data.gsmNumber,
                            text: message
                        });
                    }

                    await this.$router.push({ name: 'outgoingMessages' });
                } else {
                    showErrorMessage(this, 'Mesaj Eklemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        async validateForm() {
            if (this.dataItem.receiverId == '') {
                showValidationMessage(this, 'Kime Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.subject == '') {
                showValidationMessage(this, 'Başlık Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.type == '') {
                showValidationMessage(this, 'Talep Durumu Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.text == '') {
                showValidationMessage(this, 'Mesaj Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },

        async deletePicture(fileType) {
            let response = await this._messageService.deleteMessagePicture(fileType, this.dataItem.id, 'MessagePictures');
            if (response.isSuccess) {
                showSuccessMessage(this, 'Silme işlemi başarılı');
                if (fileType == 'PictureSlot1') {
                    this.upload1Visible = true;
                } else if (fileType == 'PictureSlot2') {
                    this.upload2Visible = true;
                } else if (fileType == 'PictureSlot3') {
                    this.upload3Visible = true;
                }
            }
        },
        async receiverSelected() {
            if(this.dataItem.receiverId == getUserId())
            {
                this.dataItem.receiverId = 0;
                showValidationMessage(this,"Kendi kendinze mesaj gönderemezsiniz")
                return;
            }
            let draftMessage = {
                receiverId: this.dataItem.receiverId,
                senderId: this.dataItem.senderId,
                parentMessageId: 0,
                text: '',
                subject: '',
                status: 'Draft',
                type: 'Normal',
                isActive:false
            };

            let insertResponse = await this._messageService.insertMessageAsDraft(draftMessage);
            if (insertResponse.isSuccess) {
                this.dataItem.id = insertResponse.data;
                this.uploadUrlForPicture1 = process.env.VUE_APP_MAIN_API + '/api/upload/file?fileType=PictureSlot1&resourceId=' + this.dataItem.id + '&resourceType=MessagePictures&managerUserId=' + getUserId();
                this.uploadUrlForPicture2 = process.env.VUE_APP_MAIN_API + '/api/upload/file?fileType=PictureSlot2&resourceId=' + this.dataItem.id + '&resourceType=MessagePictures&managerUserId=' + getUserId();
                this.uploadUrlForPicture3 = process.env.VUE_APP_MAIN_API + '/api/upload/file?fileType=PictureSlot3&resourceId=' + this.dataItem.id + '&resourceType=MessagePictures&managerUserId=' + getUserId();
            }

        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },

        async photoUploadFinished(fileType) {
            if (fileType == 'PictureSlot1') {
                this.upload1Visible = false;
            } else if (fileType == 'PictureSlot2') {
                this.upload2Visible = false;
            } else if (fileType == 'PictureSlot3') {
                this.upload3Visible = false;
            }
            this.loadingIndicator = false;
        },
        async photoUploadStarted() {
            if (await this.validateForm()) {
                if (this.dataItem.id <= 0) {
                    await this.saveDraft();
                }

                console.log(this.uploadUrl);
                this.loadingIndicator = true;
            } else {
                return;
            }

        },


    },

};

</script>


<style scoped>

</style>
